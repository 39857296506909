import { HeaderWrapper } from 'components/HeaderWrapper/HeaderWrapper';
import useApi from 'hooks/useApi';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import LogoutModal from 'components/LogoutModal/LogoutModal';
import {
  AcCircularLoader,
  Overlay,
  ProductData,
  ProductMadnessValueBlock,
  useIsDesktop,
  useIsLandscape,
} from '@appcharge/shared-ui';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  EBundlesInternalViewModel,
  ECheckoutPageEvent,
  ECollectType,
  EEventsType,
  ELocalStorageKeys,
  EPopupSubType,
  EPublisherWebhookEventType,
  EPublisherWebhookOfferEventType,
  EQueryParams,
  EResultOptions,
  EStorePhase,
  StoreBodyBlock,
} from '../../constants/enums';
import {
  OrderPopupEvents,
  collectImagesFromObject,
  extractOfferMainDetails,
  getAppVersion,
  getBadgeName,
  getPlatformData,
  getTimeLabel,
} from 'utils';
import { localStorageUtil } from 'state/localStorage.state.service';
import useUrlQuery from 'hooks/useUrlQuery';
import './style.scss';
import useCustomEvents from 'hooks/useCustomEvents';
import Footer from 'components/footer/footer';
import { dailyBonusBadgeProps } from 'components/HeaderWrapper/HeaderWrapper.types';
import { isAndroid, isIOS } from 'react-device-detect';
import { PopUpsHandler } from 'components/PopUpsHandler/PopUpsHandler';
import { useTranslation } from 'react-i18next';
import { BundlesWrapper } from './components/BundlesWrapper';
import { EOrderStatus } from '../checkout/checkout.types';
import Checkout from 'components/Checkout/Checkout';
import useSaveToHomePage from 'hooks/useSaveToHomePage';
import AddToHomePage from 'components/AddToHomePage/AddToHomePage';
import { PlayerLevelWrapper } from './components/PlayerLevelWrapper';
import { useLocalizationState } from 'state/hooks/localization.state.hook';
import { BannerWrapper } from './components/BannerWrapper';
import { MissingOffersBlock } from './components/MissingOffersBlock';
import { SpecialOffersWrapper } from './components/SpecialOffersWrapper';
import { TIME_LEFT_INTERVAL } from 'constants/defaults';
import { CreateOrderPayload } from 'constants/apiRequest.types';

const jackpotSlotsValueBlock = 'https://media.appcharge.com/media/shop-images/JMS-banner.png';
const bigFishValueBlock = 'https://media.appcharge.com/media/shop-images/BFC-banner.png';
const jackpotSlotsDesktopLogo =
  'https://media.appcharge.com/media/shop-images/PJS--welcome-logo-desktop.png';
const bigFishDesktopLogo =
  'https://media.appcharge.com/media/shop-images/BFC--welcome-logo-desktop.png';
const OFFERS_INTERVAL_TIME = 30000;

interface IShopPageProps {
  logout: (utmSource?: string) => void;
}

interface IOfferBalance {
  images: {
    product: string;
    productPrefix: string;
  };
  product: ProductData;
  quantity: string;
}

const DEFAULT_BODY_BLOCKS_ORDER: StoreBodyBlock[] = [
  StoreBodyBlock.playerLevel,
  StoreBodyBlock.banners,
  StoreBodyBlock.specialOffers,
  StoreBodyBlock.bundles,
];

export const ShopPage: React.FC<IShopPageProps> = ({ logout }) => {
  //store config states:
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [isProductMadness, setIsProductMadness] = useState(false);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  //order states:
  const [activeOrderId, setActiveOrderId] = useState<string>();
  const [processing, setProcessing] = useState(false);
  const [orderState, setOrderState] = useState<string>();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [debouncedLoader, setDebouncedLoader] = useState(false);
  const [shouldLoaderBeShown, setShouldLoaderBeShown] = useState(false);

  //offer states:
  const [selectedOffer, setSelectedOffer] = useState<OfferData>();
  const [currentPopupDisplayed, setCurrentPopupDisplayed] = useState<OfferData | any>();
  const [popupOffers, setPopupOffers] = useState<OfferData[]>([]);
  const [dailyBonusBadges, setDailyBonusBadges] = useState<dailyBonusBadgeProps[]>([]);
  const [showFailCollect, setShowFailCollect] = useState(false);
  const [timerToDisplay, setTimerToDisplay] = useState('');
  const [clickedClosePopups, setClickedClosePopups] = useState<string[]>([]);
  const [nextAvaliableDate, setNextAvaliableDate] = useState<any>(false);
  const [offersLoadedReported, setOffersLoadedReported] = useState<boolean>(false);
  const [indexToCollect, setIndexToCollect] = useState(0);
  const [popUpStartHourUTC, setPopUpStartHourUTC] = useState<number>(0);
  const [displayPostPurchase, setDisplayPostPurchase] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const API = useApi({ orderId: activeOrderId });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel } = publisherMetaData.storeTheme.general;
  const utmSource = useUrlQuery(EQueryParams.UTM_SOURCE);
  const from = useUrlQuery(EQueryParams.FROM);
  const campaignToken = useUrlQuery(EQueryParams.TOKEN);
  const [campaignOfferId, setCampaignOfferId] = useState<string>();
  const [loadingFromCampaign, setLoadingFromCampaign] = useState(from === 'campaign');
  const [popupsPresented, setPopupsPresented] = useState<string[]>([]);
  const isLoginRedirect = useUrlQuery(EQueryParams.LOGIN_REDIRECT) === 'true';
  const customEvents = useCustomEvents();
  const [initialImagesLoadingTime, setInitialImagesLoadingTime] = useState<number>();
  const [arrOffersImages, setArrOffersImages] = useState<string[]>();
  const [resultIframeSrc, setResultIframeSrc] = useState<string>('');
  const [iframeLoading, setIframeLoading] = useState(true);
  const [bodyBlocksArray, setBodyBlocksArray] = useState<React.FC[]>([]);

  const dailyBonusRef = useRef<OrderPopupEvents>(null);
  const postPurchaseRef = useRef<OrderPopupEvents>(null);
  const fetchInterval = useRef(0);

  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { isDisplayAddToHomePageButton } = useSaveToHomePage(publisherMetaData);
  const productMadnessValueBlock =
    bundlesInternalViewModel === 'jackpot-slots' ? jackpotSlotsValueBlock : bigFishValueBlock;
  const productMadnessDesktopLogo =
    bundlesInternalViewModel === 'jackpot-slots' ? jackpotSlotsDesktopLogo : bigFishDesktopLogo;
  const { currentLanguage } = useLocalizationState();

  useEffect(() => {
    let intervalId: any;

    if (activeOrderId) {
      intervalId = setInterval(async () => {
        try {
          await API.getOrder.refetch();

          const currentState = API.getOrder.data?.state;
          setOrderState(currentState);

          if (
            currentState === EOrderStatus.CHARGE_SUCCEED ||
            currentState === EOrderStatus.CHARGE_FAILED
          ) {
            clearInterval(intervalId);
          }
        } catch (err) {
          console.error(err);
          showFailedScreen();
          clearInterval(intervalId);
        }
      }, 2000);
    }

    return () => clearInterval(intervalId);
  }, [activeOrderId, campaignToken, API.getOrder.data]);

  useEffect(() => {
    if (!orderState || !activeOrderId) return;
    if (
      orderState === EOrderStatus.CHARGE_SUCCEED ||
      orderState === EOrderStatus.CHARGE_FAILED ||
      orderState === EOrderStatus.PAYMENT_FAILED
    ) {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_id: selectedOffer?.offerId,
          result:
            orderState === EOrderStatus.CHARGE_SUCCEED
              ? EResultOptions.SUCCESS
              : EResultOptions.FAILED,
          offer_name: selectedOffer?.offerName,
          platform: getPlatformData(),
          type: selectedOffer?.offerType,
          sub_type: selectedOffer?.subType,
          reason: orderState !== EOrderStatus.CHARGE_SUCCEED ? orderState : '',
          index: selectedOffer?.indexToCollect,
        },
        EStorePhase.POST_LOGIN
      );
      setProcessing(false);
      if (orderState === EOrderStatus.CHARGE_SUCCEED) {
        setShowPaymentModal(true);
      } else {
        showFailedScreen();
        setSelectedOffer(undefined);
      }
    } else {
      const timer = setTimeout(() => {
        API.getOrder.refetch().catch((err) => console.error(err));
      }, TIME_LEFT_INTERVAL);
      return () => clearTimeout(timer);
    }
  }, [orderState, activeOrderId]);

  useEffect(() => {
    if (!API.getOffersV2.data?.offers) return;

    // Sort and set popup offers by subType and priority
    const sortedPopups = API.getOffersV2.data.offers.popups?.sort((a: any, b: any) => {
      const subTypeOrder = [EPopupSubType.POST_PURCHASE, EPopupSubType.DAILY_BONUS];
      const aSubTypeIndex = subTypeOrder.indexOf(a.subType);
      const bSubTypeIndex = subTypeOrder.indexOf(b.subType);

      if (aSubTypeIndex !== bSubTypeIndex) {
        return aSubTypeIndex - bSubTypeIndex;
      }

      // Sort by priority within the same subType
      return a.priority - b.priority;
    });

    setPopupOffers(sortedPopups);

    if (popUpStartHourUTC !== API.getOffersV2?.data?.publisherSettings?.popUpStartHourUTC) {
      setPopUpStartHourUTC(API.getOffersV2?.data?.publisherSettings?.popUpStartHourUTC);
    }

    if (!offersLoadedReported) {
      const offersExtractedData = [
        ...extractOfferMainDetails(API.getOffersV2.data.offers.promotions),
        ...extractOfferMainDetails(API.getOffersV2.data.offers.bundles),
        ...extractOfferMainDetails(API.getOffersV2.data.offers.popups),
      ];
      customEvents.sendCustomEvent(
        EEventsType.OFFERS_LOADED,
        {
          specialOffers: API.getOffersV2.data.offers.promotions?.length,
          bundles: API.getOffersV2.data.offers.bundles?.length,
          popups: API.getOffersV2.data.offers.popups?.length,
          offersData: offersExtractedData,
        },
        EStorePhase.POST_LOGIN
      );
      API.sendPublisherWebhookEvent.mutate({
        eventType: EPublisherWebhookEventType.OFFER,
        data: {
          eventName: EPublisherWebhookOfferEventType.OFFERS_LOADED,
          appVersion: getAppVersion(),
          offers: offersExtractedData,
        },
      });
      setOffersLoadedReported(true);
    }

    if (
      !isProductMadness &&
      bundlesInternalViewModel &&
      [EBundlesInternalViewModel.JACKPOT_SLOTS, EBundlesInternalViewModel.BIG_FISH_CASINO].includes(
        bundlesInternalViewModel
      )
    ) {
      setIsProductMadness(true);
    }
  }, [API.getOffersV2.data]);

  useEffect(() => {
    const popupToDisplay = popupOffers?.find(
      (popup: OfferData) =>
        popup.subType === EPopupSubType.POST_PURCHASE ||
        (popup.isAllowedToCollect && popup.indexToCollect)
    );
    setCurrentPopupDisplayed(popupToDisplay);

    if (popupToDisplay) {
      reportPopupImpression(popupToDisplay);
      if (popupToDisplay?.indexToCollect) setIndexToCollect(popupToDisplay?.indexToCollect - 1);
    }
  }, [popupOffers]);

  useEffect(() => {
    const newBadges = popupOffers
      ?.filter((popup: OfferData) => {
        const isExistBadge = dailyBonusBadges?.some((badge) => badge.offerId === popup.offerId);
        const isExistBadgeCoverImage = popup?.offerUi?.badgeCoverImage;
        return (
          !popup.isAllowedToCollect &&
          !isExistBadge &&
          popup.subType === EPopupSubType.DAILY_BONUS &&
          isExistBadgeCoverImage
        );
      })
      .map((badge) => ({
        offerId: badge?.offerId,
        font: publisherMetaData.storeTheme.general.font,
        badgeCoverImage: badge?.offerUi?.badgeCoverImage,
        borderColor: badge?.offerUi?.borderColor || {},
        showEvery: badge?.showEvery,
      }));

    if (newBadges && newBadges.length > 0) {
      setDailyBonusBadges((prevBadges: any) => [...prevBadges, ...newBadges]);
    }
    if (popUpStartHourUTC === undefined) return;
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setUTCHours(popUpStartHourUTC, 0, 0);
    // Check if the hour is before the current hour, if so, move to the next day
    if (nextDate.getTime() < currentDate.getTime()) {
      nextDate.setUTCDate(nextDate.getUTCDate() + 1);
    }
    setNextAvaliableDate(nextDate);
  }, [currentPopupDisplayed, popUpStartHourUTC, popupOffers, setPopupOffers]);

  useEffect(() => {
    const dailyBonusBadgesLength = dailyBonusBadges.length;
    if (dailyBonusBadgesLength > 0) {
      calculateTimeLeft(dailyBonusBadges[dailyBonusBadgesLength - 1]);
    }
  }, [dailyBonusBadges]);

  useEffect(() => {
    if (API.getOffersV2.isError) {
      customEvents.resetSession();
      logout();
    }
  }, [API.getOffersV2.isError]);

  useEffect(() => {
    document.querySelector('body')?.classList.remove('screen-lock');
  }, []);

  const finishBundlesLoad = () => {
    setAllImagesLoaded(true);

    if (initialImagesLoadingTime) {
      const now = Date.now();

      const loadingTimeInMs = now - initialImagesLoadingTime;

      customEvents.sendCustomEvent(
        EEventsType.OFFERS_IMAGES_LOADED,
        {
          offers:
            API.getOffersV2.data.offers.bundles?.length +
            API.getOffersV2.data.offers.promotions?.length +
            API.getOffersV2.data.offers.popups?.length,
          loadingTime: loadingTimeInMs,
          images: arrOffersImages,
        },
        EStorePhase.POST_LOGIN
      );
    }
  };

  const getHasOffers = (getOffersV2: any): boolean => {
    if (!getOffersV2?.data?.offers || Object.keys(getOffersV2.data.offers).length === 0)
      return false;

    if (
      getOffersV2.data.offers.bundles?.length ||
      getOffersV2.data.offers.popups?.length ||
      getOffersV2.data.offers.promotions?.length
    )
      return true;
    return false;
  };

  useEffect(() => {
    if (!API.getOffersV2?.data?.offers) return;
    let loadedImagesCount = 0;

    const allImagesSet: Set<string> = new Set();

    if (!getHasOffers(API.getOffersV2)) {
      finishBundlesLoad();
      return;
    }

    API.getOffersV2?.data.offers?.bundles?.forEach((bundle: OfferData) => {
      collectImagesFromObject(bundle, allImagesSet);
    });
    API.getOffersV2?.data.offers?.promotions?.forEach((promotion: OfferData) => {
      collectImagesFromObject(promotion, allImagesSet);
    });
    API.getOffersV2?.data.offers?.popups?.forEach((popup: OfferData) => {
      collectImagesFromObject(popup, allImagesSet);
    });

    const allImagesArray = Array.from(allImagesSet);

    const isOnlyNoImage = allImagesArray.length === 1 && allImagesArray[0] === 'noImage';

    if (isOnlyNoImage) {
      finishBundlesLoad();
      return;
    }
    const arrImages = allImagesArray.filter((curr) => curr !== 'noImage');
    setAllImagesLoaded(false);
    setArrOffersImages(arrImages);

    arrImages.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImagesCount += 1;
        if (loadedImagesCount + 2 == arrImages.length || arrImages.length <= 2) {
          finishBundlesLoad();
        }
      };
      img.onerror = () => {
        customEvents.sendCustomEvent(
          EEventsType.OFFERS_IMAGE_FAILED_LOADING,
          {
            offers:
              API.getOffersV2.data.offers.bundles?.length +
              API.getOffersV2.data.offers.promotions?.length +
              API.getOffersV2.data.offers.popups?.length,
            images: arrImages,
            image: img.src,
          },
          EStorePhase.POST_LOGIN
        );
        console.error('Failed to load:', img.src);
      };
    });
  }, [API.getOffersV2?.data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      finishBundlesLoad();
    }, 10000);

    const now = Date.now();
    setInitialImagesLoadingTime(now);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  useEffect(() => {
    if (!publisherMetaData.supportConfiguration?.postLoginSnippet) {
      setIsScriptLoaded(true);
    }
    const backgroundImage =
      isDesktop || isLandscape
        ? publisherMetaData.storeTheme.general.backgroundImageDesktop
        : publisherMetaData.storeTheme.general.backgroundImageMobile;
    document.body.setAttribute(
      'style',
      `background-image: url(${backgroundImage}); background-size: cover; background-attachment: fixed; background-position: top center`
    );

    const checkIframeVisibility = () => {
      const iframe = document.getElementById('hs-web-sdk-iframe');
      if (iframe && window.getComputedStyle(iframe).visibility === 'visible') {
        setIsScriptLoaded(true);
        return true;
      }
      return false;
    };

    const script = document.createElement('script');
    script.src = publisherMetaData.supportConfiguration?.postLoginSnippet;
    script.onload = () => {
      if (!checkIframeVisibility()) {
        const intervalId = setInterval(() => {
          if (checkIframeVisibility()) {
            clearInterval(intervalId);
          }
        }, 100);
      }
    };
    document.body.appendChild(script);
    const onFocus = () => {
      keepAlive();
    };
    const onBlur = () => {
      clearTimeout(fetchInterval.current);
    };
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
      document.body.removeChild(script);
    };
    // eslint-disable-next-line
  }, [isDesktop, isLandscape]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const init = async () => {
      customEvents.sendCustomEvent(
        EEventsType.SHOP_LAND,
        {
          player_activity: isLoginRedirect ? 'post login' : 'normal',
          platform: getPlatformData(),
          source: sessionStorage.getItem('source'),
          add_to_home_screen_appear: isDisplayAddToHomePageButton,
        },
        EStorePhase.POST_LOGIN
      );
      API.sendShopLand.mutate();
      await API.getPostLoginData.refetch();
      API.getPaypalKey.refetch();
    };
    init();
    return () => {
      clearTimeout(fetchInterval.current);
    };
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const reportPopupImpression = (offer: OfferData) => {
    if (popupsPresented.includes(offer.offerId)) return;
    setPopupsPresented([...popupsPresented, offer.offerId]);
    API.sendPublisherWebhookEvent.mutate({
      eventType: EPublisherWebhookEventType.OFFER,
      data: {
        eventName: EPublisherWebhookOfferEventType.OFFERS_SHOWN,
        offers: extractOfferMainDetails([offer]),
        appVersion: getAppVersion(),
      },
    });
  };

  const keepAlive = async () => {
    if (!localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN)) {
      return logout(utmSource || undefined);
    }

    API.keepAlive.mutate(undefined, {
      onError: () => {
        customEvents.sendCustomEvent(
          EEventsType.LOGOUT,
          {
            reason: 'session ended',
          },
          EStorePhase.PRE_LOGIN
        );
        customEvents.resetSession();
        logout();
      },
      onSuccess: () => {
        customEvents.setDistinctId();
        fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
        if (!isLoggedIn) setIsLoggedIn(true);
      },
    });
  };

  useEffect(() => {
    if (API.keepAlive.isIdle) {
      keepAlive();
    }
  }, [API.keepAlive.isIdle]);

  const openLogoutModal = () => {
    customEvents.sendCustomEvent(EEventsType.PROFILE_MENU_CLICKED, {}, EStorePhase.POST_LOGIN);
    setShowLogoutModal(true);
  };

  const fetchIntervalHandler = () => {
    if (!document.hasFocus()) return;
    fetchInterval.current = Number(setTimeout(fetchIntervalHandler, OFFERS_INTERVAL_TIME));
  };

  useEffect(() => {
    if (!publisherMetaData.featureFlags?.store_refresh_offers_stale_mode) return;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        API.getOffersV2.refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [publisherMetaData.featureFlags]);

  useEffect(() => {
    const loadingState = processing || !allImagesLoaded || loadingFromCampaign;
    setShouldLoaderBeShown(loadingState);
  }, [processing, allImagesLoaded]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedLoader(shouldLoaderBeShown);
    }, 300);

    return () => clearTimeout(timer);
  }, [shouldLoaderBeShown]);

  const onCollect = async (offerId: string): Promise<void> => {
    setProcessing(true);
    const freeOrderData: Partial<CreateOrderPayload> = {
      clientGaId: localStorage.getItem('ga_client_id') || '2134654321',
      paymentProvider: 'free',
      paymentMethod: 'free',
      utmSource: null,
      offerId: offerId,
    };
    try {
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_CLICKED,
        {
          offer_id: selectedOffer?.offerId,
          offer_name: selectedOffer?.offerName,
          platform: getPlatformData(),
          type: ECollectType.OFFER,
        },
        EStorePhase.POST_LOGIN
      );

      const response = await API.createOrderV2.mutateAsync(freeOrderData);
      const orderId = response?.data?.orderId;

      if (orderId) {
        setActiveOrderId(orderId);
      }
    } catch (error) {
      console.error('creating an order failed', error);
      navigate('../failed?msg=creating order error');
    }
  };

  const selectOffer = (offerData: OfferData) => {
    const priceData = offerData?.productsSequence[indexToCollect]?.price;
    if (priceData.price === 0) {
      localStorageUtil.set(ELocalStorageKeys.IS_FREE_ORDER_SELECTED, true);
    } else {
      localStorageUtil.remove(ELocalStorageKeys.IS_FREE_ORDER_SELECTED);
    }
    setSelectedOffer(offerData);

    if (priceData.price === 0) {
      onCollect(offerData.offerId);
    } else {
      setShowPaymentModal(true);
      const products = offerData?.productsSequence[indexToCollect]?.products.map((product) => {
        const { name, quantity } = product;
        return {
          name,
          quantity,
        };
      });
      customEvents.sendCustomEvent(
        EEventsType.OFFER_CLICKED,
        {
          offer_id: offerData.offerId,
          offer_name: offerData.offerName,
          badge_left: getBadgeName('left', offerData.dynamicOfferUi?.badges),
          badge_right: getBadgeName('right', offerData.dynamicOfferUi?.badges),
          badge_center: getBadgeName('center', offerData.dynamicOfferUi?.badges),
          sale_percentage: offerData.dynamicOfferUi?.salePercentage,
          type: offerData.offerType,
          sub_type: offerData?.subType,
          products,
          bundle_price_usd: priceData.price,
          player_local_currency: priceData.currencyCode,
          bundle_price_total: priceData.price,
          offer_id_external: offerData.publisherOfferId,
          offer_internal_id: offerData.offerId,
        },
        EStorePhase.POST_LOGIN
      );
    }
  };

  const closePopup = () => {
    setShowLogoutModal(false);
    setShowPaymentModal(false);
    setActiveOrderId(undefined);
    setCurrentPopupDisplayed(undefined);
    setSelectedOffer(undefined);
    setLoadingFromCampaign(false);
    setCampaignOfferId(undefined);
  };

  const getBalancesData = () => {
    const data: IOfferBalance[] = API.getOffersV2?.data?.balances;
    if (!data) return;
    return data.map((item) => ({
      ...item,
      image: item.images.productPrefix || item.images.product,
      amount: item.quantity,
    }));
  };

  const assignNextPopUp = () => {
    if (!popupOffers || popupOffers.length <= 0) {
      setCurrentPopupDisplayed(undefined);
      return;
    }

    const eligibleOffer = popupOffers.find(
      (offer) =>
        !clickedClosePopups.includes(offer.offerId) &&
        currentPopupDisplayed?.offerId !== offer.offerId
    );

    if (eligibleOffer) {
      reportPopupImpression(eligibleOffer);
      if (eligibleOffer?.indexToCollect) setIndexToCollect(eligibleOffer.indexToCollect - 1);
      setCurrentPopupDisplayed(eligibleOffer);
    }
  };

  const calculateNextDate = (popupInterval: OfferData, nextAvaliableDate: Date | null): Date => {
    const showEvery = popupInterval?.showEvery;
    const nextDate = nextAvaliableDate || new Date();

    if (showEvery && showEvery < 1440) {
      const currentDate = new Date();
      return new Date(currentDate.getTime() + showEvery * 60000);
    }
    return nextDate;
  };

  const calculateTimeLeft = (popupInterval: any) => {
    const nextDate = calculateNextDate(popupInterval, nextAvaliableDate);
    if (nextDate) {
      const interval = setInterval(() => {
        const remainingTime = nextDate.getTime() - new Date().getTime();

        if (remainingTime < TIME_LEFT_INTERVAL) {
          clearInterval(interval);
          setTimerToDisplay('');
          API.getOffersV2.refetch();
        } else {
          setTimerToDisplay(getTimeLabel(remainingTime));
        }
      }, TIME_LEFT_INTERVAL);
      return () => clearInterval(interval);
    }
  };

  //open next product badge
  const onClickBadge = (offerId?: string) => {
    const clickedPopupBadge = popupOffers.find((offer) => offer.offerId === offerId);
    if (clickedPopupBadge) {
      setCurrentPopupDisplayed(null);
      setTimeout(() => {
        const updatedPopup = {
          ...clickedPopupBadge,
          isBadgeNextProduct: true,
        };
        if (updatedPopup?.indexToCollect) setIndexToCollect(updatedPopup.indexToCollect - 1);
        setCurrentPopupDisplayed(updatedPopup);
      }, 0);
    }
  };

  const showFailedScreen = () => {
    setShowFailCollect(true);
    setResultIframeSrc(
      `${window.location.origin}/failed?${EQueryParams.IS_IFRAME}=true&error=storeFailure`
    );
  };

  useEffect(() => {
    const eventHandler = (massageEvent: any) => {
      if (massageEvent.origin !== window.location.origin) return;
      const { params, event } = massageEvent.data;
      if (event === ECheckoutPageEvent.BACK_TO_STORE) {
        setDisplayPostPurchase(true);
        setShowFailCollect(false);
        API.getOffersV2.refetch();
        return;
      }
      if (event === ECheckoutPageEvent.SUPPORT) {
        navigate(params.supportUrl);
        return;
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  // This is "hack" for Firefox issue with "onLoad" call
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'iframe-loaded') {
        setIframeLoading(false);
        event.source?.postMessage('stop-messages');
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    API.getOffersV2.refetch();
  }, [currentLanguage]);

  useEffect(() => {
    if (from === 'campaign' && campaignToken) {
      API.getStoreCampaign.mutate(campaignToken, {
        onSuccess: (data: string) => {
          setCampaignOfferId(data);
        },
      });
    }
  }, [from, campaignToken]);

  const mapBlocksToComponents: Record<StoreBodyBlock, React.FC> = useMemo(
    () => ({
      [StoreBodyBlock.playerLevel]: PlayerLevelWrapper,
      [StoreBodyBlock.banners]: BannerWrapper,
      [StoreBodyBlock.specialOffers]: () => <SpecialOffersWrapper selectOffer={selectOffer} />,
      [StoreBodyBlock.bundles]: () => <BundlesWrapper selectOffer={selectOffer} />,
    }),
    []
  );

  useEffect(() => {
    const arrBlocksOrder: StoreBodyBlock[] =
      publisherMetaData.storeTheme?.storeTemplate?.bodyBlocksOrder || DEFAULT_BODY_BLOCKS_ORDER;

    const arrBodyBlocksComponents: React.FC[] = arrBlocksOrder.map(
      (block: StoreBodyBlock) => mapBlocksToComponents[block]
    );

    setBodyBlocksArray(arrBodyBlocksComponents);
  }, [publisherMetaData.storeTheme?.storeTemplate?.bodyBlocksOrder]);

  // if (from === 'campaign' && session) {
  //   return (
  //     <div
  //       // className={isSettled ? 'hide-iframe' : ''}
  //       style={{
  //         position: 'fixed',
  //         top: '0',
  //         left: '0',
  //         height: '100lvh',
  //         width: '100vw',
  //         zIndex: '1000',
  //       }}
  //     >
  //       <AppchargeCheckout
  //         checkoutToken={publisherMetaData.integration.checkoutPublicKey}
  //         checkoutUrl={session?.url}
  //         sessionToken={session?.token}
  //         playerId={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerId}
  //         referrerUrl={''}
  //         onClose={() => {
  //           close();
  //           document.body.classList.remove('screen-lock');
  //         }}
  //         onOrderCreated={() => {
  //           // validateOfferData(event.products);
  //         }}
  //         onInitialLoad={() => {
  //           setProcessing(false);
  //           document.body.classList.add('screen-lock');
  //         }}
  //         onOrderCompletedSuccessfully={() => {}}
  //         onOrderCompletedFailed={() => {}}
  //         onPaymentIntentSuccess={() => {}}
  //         // locale={locale as AppchargeLocale}
  //       />
  //     </div>
  //   );
  // }

  useEffect(() => {
    if (publisherMetaData.featureFlags?.store_hide_mobile_background_overlay) {
      let viewportMeta = document.querySelector('meta[name="viewport"]');
      if (!viewportMeta) {
        viewportMeta = document.createElement('meta');
        viewportMeta.setAttribute('name', 'viewport');
        document.head.appendChild(viewportMeta);
      }
      viewportMeta.setAttribute(
        'content',
        'initial-scale=1, width=device-width, height=device-height, viewport-fit=cover, user-scalable=no'
      );
    }
  }, [publisherMetaData]);

  return (
    <div className="storeWrapper">
      <AddToHomePage publisherMetaData={publisherMetaData} customEvents={customEvents} />
      {(isAndroid || isIOS) &&
        !publisherMetaData.featureFlags?.store_hide_mobile_background_overlay && (
          <div
            className={'shop__mobile-img'}
            style={{
              backgroundImage: `url(${publisherMetaData.storeTheme.general.backgroundImageMobile})`,
              position: 'fixed',
              zIndex: '-1',
              backgroundSize: 'cover',
              backgroundPosition: 'top center',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></div>
        )}

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <HeaderWrapper
          showHeader={API.getOffersV2.data !== undefined}
          balanceQuantityFormat={publisherMetaData.storeTheme.general.balanceQuantityFormat}
          playerName={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerName}
          openLogoutModal={openLogoutModal}
          logoSize={publisherMetaData.storeTheme.general.logoSize}
          balances={getBalancesData()}
          fontFamily={publisherMetaData.storeTheme.general.font}
          profileFrameImg={API.getOffersV2?.data?.profileFrameImg || ''}
          playerLevelImg={API.getOffersV2?.data?.playerLevelImg || ''}
          dailyBonusBadges={timerToDisplay ? dailyBonusBadges : []}
          timer={timerToDisplay}
          onClickBadge={onClickBadge}
        />
      )}
      {debouncedLoader && (
        <Overlay overlayPercentage={80} zIndex={10000}>
          <AcCircularLoader text={t('processing')} />
        </Overlay>
      )}
      {!showPaymentModal && (
        <PopUpsHandler
          setProcessing={setProcessing}
          onPurchaseClick={selectOffer}
          currentPopupDisplayed={currentPopupDisplayed}
          assignNextPopUp={assignNextPopUp}
          setClickedClosePopups={setClickedClosePopups}
          nextAvaliableDate={nextAvaliableDate}
          setNextAvaliableDate={setNextAvaliableDate}
          dailyBonusRef={dailyBonusRef}
          postPurchaseRef={postPurchaseRef}
          indexToCollect={indexToCollect}
          displayPostPurchase={displayPostPurchase}
          setDisplayPostPurchase={setDisplayPostPurchase}
        />
      )}

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <div className="offers-wrapper">
          {isProductMadness && (
            <ProductMadnessValueBlock
              mobileLogoImage={publisherMetaData.storeTheme.general.logo}
              desktopLogoImage={productMadnessDesktopLogo}
              backgroundImage={productMadnessValueBlock}
              playerLevelImage={API.getOffersV2?.data?.playerLevelImg}
              storeSaleImage={publisherMetaData.storeTheme.general?.storeSaleImage}
            />
          )}

          {bodyBlocksArray.map((Block: React.FC, i) => (
            <Block key={i} />
          ))}

          <MissingOffersBlock />

          {bundlesInternalViewModel === EBundlesInternalViewModel.BAGELCODE &&
            API.getOffersV2?.data?.playerLevelImg && (
              <div className="player-level-image">
                <img src={publisherMetaData.storeTheme.general.logo} alt="logo" />
                <img alt="Player Level" src={API.getOffersV2?.data?.playerLevelImg} />
              </div>
            )}
        </div>
      )}

      {!publisherMetaData.featureFlags.store_deeplinks_disable_store && (
        <Footer showFooter={API.getOffersV2.data !== undefined} isScriptLoaded={isScriptLoaded} />
      )}
      {showLogoutModal && (
        <LogoutModal
          playerName={localStorageUtil.get(ELocalStorageKeys.PLAYER_DATA)?.playerName}
          closePopup={closePopup}
          showLogoutModal={showLogoutModal}
          logout={logout}
        />
      )}
      {showFailCollect && (
        <Overlay overlayPercentage={80}>
          {iframeLoading && <AcCircularLoader text={t('processing')} />}
          <iframe
            title={'store resolve'}
            className="resolve-iframe"
            src={resultIframeSrc}
            style={{
              visibility: iframeLoading ? 'hidden' : 'visible',
              transition: 'visibility 1s easy-in-out',
            }}
          ></iframe>
        </Overlay>
      )}
      {((selectedOffer && showPaymentModal) || campaignOfferId) && (
        <Checkout
          selectedOffer={(campaignOfferId as string) || (selectedOffer as OfferData)}
          close={() => closePopup()}
          setProcessing={setProcessing}
          setLoadingFromCampaign={setLoadingFromCampaign}
          collectId={activeOrderId}
          currencyCode={selectedOffer?.productsSequence[indexToCollect]?.price?.currencyCode}
        />
      )}
    </div>
  );
};
